<template>
  <b-modal
    :visible="visible"
    title="Confirmar cadastro"
    size="xl"
    hide-footer
    @hide="close"
  >
    <b-row>
      <b-col cols="10">
        <form-input v-model="client.cpf_cnpj" icon="building" disabled />
      </b-col>
      <b-col>
        <b-button variant="primary" type="submit" @click="searchCnpj">
          BUSCAR
        </b-button>
      </b-col>
    </b-row>

    <b-form @submit.prevent="submit">
      <b-form-row>
        <b-col>
          <form-input
            v-model="form.razao_social"
            icon="person-lines-fill"
            label="Razão Social:"
            placeholder="Informe a razão social"
            required
          />
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-input
            v-model="form.desconto_tabela"
            icon="tag"
            label="Desconto Tabela:"
            type="number"
            placeholder="Informe o desconto tabela"
          />
        </b-col>
        <b-col>
          <form-input
            v-model="form.desconto_extra"
            icon="tag"
            label="Desconto Extra:"
            type="number"
            placeholder="Informe o desconto extra"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <form-input
            v-model="form.endereco"
            icon="signpost-split"
            label="Edereço:"
            placeholder="Informe o endereço"
            required
          />
        </b-col>

        <b-col cols="3">
          <form-input
            v-model="form.numero"
            icon="mailbox"
            label="Numero:"
            placeholder="Informe o numero"
            required
          />
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-input
            v-model="form.complemento"
            icon="card-text"
            label="Complemento:"
            placeholder="Informe o complemento"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <form-input
            v-model="form.bairro"
            icon="map"
            label="Bairro:"
            placeholder="Informe o bairro"
            required
          />
        </b-col>

        <b-col>
          <form-input
            v-model="form.cep"
            icon="signpost-split"
            label="CEP:"
            placeholder="Informe o CEP"
            required
          />
        </b-col>
        <b-col>
          <form-input
            v-model="form.cidade"
            icon="pin-map"
            label="Cidade:"
            placeholder="Informe a cidade"
            required
          />
        </b-col>
      </b-form-row>

      <b-button variant="primary" type="submit">SALVAR</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import FormInput from "../form/FormInput.vue";

export default {
  components: { FormInput },
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  name: "ConfirmLogin",
  data() {
    return {
      form: {
        razao_social: "",
        cep: "",
        bairro: "",
        numero: "",
        municipio: "",
        endereco: "",
        complemento: "",
      },
    };
  },
  computed: {
    ...mapState("client", ["client"]),
  },
  methods: {
    ...mapActions("client", ["markCompleted"]),

    async searchCnpj() {
      const { data } = await axios.get(
        `https://minhareceita.org/${this.client.cpf_cnpj}`
      );

      const {
        razao_social,
        bairro,
        numero,
        municipio,
        logradouro,
        complemento,
        cep,
      } = data;

      this.form = {
        razao_social,
        bairro,
        numero,
        cidade: municipio,
        endereco: logradouro,
        complemento,
        cep,
      };
    },
    async submit() {
      await this.markCompleted({ id: this.client.id, form: this.form });
      this.close();
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style></style>
