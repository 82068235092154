<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Clientes</h4>
        </div>
        <b-tabs fill>
          <b-tab title="CADASTROS PENDENTES" @click="getAllPending">
            <b-card-text class="d-flex flex-column">
              <b-table
                class="lvj-table"
                :items="pendingDateFormat"
                :fields="fieldsPending"
                :busy="isLoading"
                bordered
                striped
                hover
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-2"
                    title="Confirmar Cadastro do Cliente"
                    @click="openConfirmLoginModal(row.item)"
                  >
                    <b-icon-person />
                  </b-button>
                  <b-button
                    variant="secondary"
                    size="sm"
                    class="mr-2"
                    title="Excluir Cadastro do Cliente"
                    @click="deleteUnregisteredClient(row.item.id)"
                  >
                    <b-icon-trash />
                  </b-button>
                </template>
              </b-table>
              <b-button @click="morePending">
                <b-icon
                  icon="arrow-clockwise"
                  animation="spin"
                  v-if="isLoading"
                />
                CARREGAR MAIS
              </b-button>
            </b-card-text>
          </b-tab>

          <b-tab title="CLIENTES" @click="getAllClients">
            <b-card-text class="d-flex flex-column">
              <b-table
                class="lvj-table"
                :items="clientDateFormat"
                :fields="fieldsClient"
                :busy="isLoading"
                bordered
                striped
                hover
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-2"
                    title="Ver Informações do Cliente"
                    @click="changeRoute(row.item)"
                  >
                    <b-icon-pencil />
                  </b-button>
                </template>
              </b-table>
              <b-button @click="moreClient">
                <b-icon
                  icon="arrow-clockwise"
                  animation="spin"
                  v-if="isLoading"
                />
                CARREGAR MAIS
              </b-button>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <confirm-login v-model="confirmLoginModal" />
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { maskCpfCnpj } from "../../utils/maskCpfCnpj";
import { maskPhone } from "../../utils/maskPhone";

import ConfirmLogin from "../../components/client/ConfirmLogin.vue";

export default {
  components: { ConfirmLogin },
  name: "listClients",
  data() {
    return {
      pagePending: 2,
      pageClient: 2,
      confirmLoginModal: false,
      fieldsClient: [
        {
          key: "razao_social",
          label: "Razão Social",
          sortable: true,
        },
        {
          key: "cpf_cnpj",
          label: "CPF/CNPJ",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      fieldsPending: [
        {
          key: "cpf_cnpj",
          label: "CPF/CNPJ",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "telefone",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data Recebido",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("client", ["pending", "clients", "isLoading"]),

    clientDateFormat() {
      return this.clients.map((e) => ({
        ...e,
        cpf_cnpj: maskCpfCnpj(e.cpf_cnpj),
        created_at: new Date(
          new Date(e.created_at).getTime() + 3 * (60 * 60 * 1000)
        ).toLocaleString("pt-BR"),
      }));
    },
    pendingDateFormat() {
      return this.pending.map((e) => ({
        ...e,
        cpf_cnpj: maskCpfCnpj(e.cpf_cnpj),
        telefone: maskPhone(e.telefone),
        created_at: new Date(
          new Date(e.created_at).getTime() + 3 * (60 * 60 * 1000)
        ).toLocaleString("pt-BR"),
      }));
    },
  },
  methods: {
    ...mapActions("client", [
      "getAllPending",
      "getMorePending",
      "getAllClients",
      "getMoreClients",
      "getOneClient",
      "deleteUnregisteredClient",
    ]),
    ...mapMutations("client", ["setOneClient", "resetClient"]),

    openConfirmLoginModal(client) {
      this.setOneClient(client);
      this.confirmLoginModal = true;
    },
    changeRoute(client) {
      this.resetClient();
      this.getOneClient(client.id);
      this.$router.push(`clientes/${client.id}/detalhes`);
    },
    async morePending() {
      const { pagePending } = this;
      await this.getMorePending(pagePending);
      this.pagePending = pagePending + 1;
    },
    async moreClient() {
      const { pageClient } = this;
      await this.getMoreClients(pageClient);
      this.pageClient = pageClient + 1;
    },
  },
  async created() {
    await Promise.all([this.getAllPending(), this.getAllClients()]);
  },
};
</script>

<style></style>
